<script setup lang="ts">
onErrorCaptured((error) => {
    console.error('[CAPTURED ERROR]', error);
    return false;
});
</script>

<template>
    <div>
        <slot />
    </div>
</template>
